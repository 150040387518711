<template>
  <el-date-picker
      :placeholder="`${$t('system.enter_text')}...`"
      :size="size"
      @change="onChange"
      class="w-100"
      v-model="filter.value"
  />
</template>

<script>
import moment from 'moment';
import filtersGeneratorMixin from '../mixin/filtersGeneratorMixin';

export default {
  name: 'fieldDateTimePicker',
  mixins: [filtersGeneratorMixin],
  methods: {
    onChange(value) {
      this.filter.value = moment(value).format('YYYY-MM-DD');
    },
  },
};
</script>

<style scoped>

</style>
